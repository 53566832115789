import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  padding: 20px;
  position: relative;
  color: #fff;
  width: 100%;
    justify-content: center;
    align-items: center;
`;

export const ConnectButton = styled.button`
  position: absolute;
  z-index: 1;
  top: 20px;
  right: 20px;
  padding: 5px 10px;
  border: 2px solid #000;
  background-color: #fff;
  font-weight: bold;
  font-size: 10px;
  color: #000;
  cursor: pointer;
  transition: all 0.3s;
  border-radius: 3px;
  box-shadow: 2px 2px #000;
  opacity: 0.8;

  &:hover {
    background-color: #f0f0f0;
    transform: translate(-1px, -1px);
    box-shadow: 3px 3px #000;
  }

  &:active {
    box-shadow: none;
    transform: translate(0, 0);
  }

  cursor: url('https://dengboz.com/assets/images/cursor_on.png') 16 0, auto;

`;

export const ConnectedStatus = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 8px;
  color: #000;
  opacity: 0.6;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  margin: 20px 0;
  border-radius: 2px;
  text-align: center;
  opacity: 0.8;
  width: 100%;
  max-width : 600px;
`;

export const MintContainer = styled(Container)`
  width: 100%;
  max-width : 600px;
`;



export const Image = styled.img`

`;





export const StatusContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 10px 0;

  @media (max-width: 325px) {
    flex-direction: column;
  }
`;

export const AmountContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: auto;
  margin-left: 20px;
  margin-top: 0px;

  @media (max-width: 325px) {
    justify-content: center;
     margin-left:0px;
     margin-top: 10px;
  }
`;


export const AmountButton = styled.button`

  width: 25px;
  height: 25px;
  border: 2px solid #000;
  background-color: #fff;
  font-weight: bold;
  font-size: 15px;
  color: #000;
  cursor: pointer;
  transition: all 0.3s;
  border-radius: 3px;
  box-shadow: 2px 2px #000;

  &:hover {
    background-color: #f0f0f0;
    transform: translate(-1px, -1px);
    box-shadow: 3px 3px #000;
  }

  &:active {
    box-shadow: none;
    transform: translate(0, 0);
  }

  cursor: url('https://dengboz.com/assets/images/cursor_on.png') 16 0, auto;
`;

export const StyledButton = styled.button`
  width: 100%;
  padding: 10px 20px;
  border: 2px solid #000;
  background-color: #fff;
  font-weight: bold;
  font-size: 16px;
  color: #000;
  cursor: pointer;
  margin-top: 10px;
  transition: all 0.3s;
  border-radius : 3px;
  box-shadow: 2px 2px #000;
  display: flex;
  flex-direction: column;
  align-items: center;

  &:hover {
    background-color: #f0f0f0;
    transform: translate(-2px, -2px);
    box-shadow: 4px 4px #000;
  }

  &:active {
    box-shadow: none;
    transform: translate(0, 0);
  }
    
  

  cursor: url('https://dengboz.com/assets/images/cursor_on.png') 16 0, auto;
`;

export const MintButtonText = styled.div`
  font-size: 6vw;
  font-weight: bold;
  letter-spacing: 5px
`;

export const PriceText = styled.div`
  font-size: 3w;
  margin-top: 5px;
`;

export const MintInfo = styled.div`
  margin-top: 10px;
  padding: 10px;
  border-radius : 3px;
  text-align: center;
  font-size: 12px;
  opacity : 0.6;
`;


export const Feedback = styled.div`
  margin-top: 20px;
  font-size : 10px;
  opacity : 0.8;
  color: ${(props) => (props.isError ? "red" : "black")};
  background-color: ${(props) => (props.isError ? "#333" : "")};
  
  
`;

export const InfoIcon = styled.div`
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
  color: #000;
  position: absolute;
  top: 20px;
  left: 20px;
  
  background-color: #f5f5f5;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;

  border: 2px solid #000;
  cursor: url('https://dengboz.com/assets/images/cursor_on.png') 16 0, auto;
 
`;


export const Modal = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #e6dfd8;
  padding: 20px;
  border-radius: 2px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.5);
  z-index: 1000;
  max-width: 80%;
  width: 400px;
  color : #000;
  border: 2px solid #000;
  opacity: 0.95;
  
`;

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: 999;
`;

export const CloseButton = styled.button`
  width: 25px;
  height: 25px;
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: #e6dfd8;
  border: 2px solid #000;
  font-weight: bold;

  color: #000;
  cursor: pointer;
  border-radius: 2px;
  padding: 5px;
  transition: all 0.3s;
  box-shadow: 2px 2px #000;

  &:hover {
    transform: translate(-1px, -1px);
    box-shadow: 3px 3px #000;
  }

  &:active {
    box-shadow: none;
    transform: translate(0, 0);
  }

  cursor: url('https://dengboz.com/assets/images/cursor_on.png') 16 0, auto;
`;

export const GaugeBarContainer = styled.div`
  position: relative;
  width: 100%;
  height: 20px;
  background-color: #ddd;
  border-radius: 2px;
  box-shadow: inset 2px 2px 5px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #000;
  box-shadow: 2px 2px #000;
  
`;

export const GaugeBarFill = styled.div`
  width: ${(props) => props.fillPercentage}%;
  height: 100%;
  background-color: ${(props) => props.backgroundColor}; /* 전달된 색상 사용 */
  border: 2px solid ${(props) => props.backgroundColor};
  transition: width 2s ease-in-out;
  position: absolute;
  left: 0;
  top: 0;
`;

export const GaugeBarText = styled.span`
  position: relative;
  z-index: 3; /* 텍스트가 가장 위에 표시 */
  font-size: 7px;
  color: #000;
`;
