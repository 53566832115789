import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect, fetchTotalSupply } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import {
  Wrapper,
  ConnectButton,
  ConnectedStatus,
  MintContainer,
  Image,
  AmountContainer,
  AmountButton,
  StyledButton,
  MintButtonText,
  PriceText,
  Feedback,
  MintInfo,
  InfoIcon,
  Modal,
  ModalOverlay,
  CloseButton,
  GaugeBarContainer,
  GaugeBarFill,
  GaugeBarText,
  StatusContainer
} from "./styles/styles";


const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input;

function App() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [claimingNft, setClaimingNft] = useState(false);
  const [feedback, setFeedback] = useState(`Connect your wallet`);
  const [mintAmount, setMintAmount] = useState(1);
  const [userNFTBalance, setUserNFTBalance] = useState(0);
  const [totalSupply, setTotalSupply] = useState(0);
  const [showModal, setShowModal] = useState(false); // State to toggle Modal
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    MAX_FREE_MINTS: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
  });
  const eyeImgRef = useRef(null);
  const [currentImage, setCurrentImage] = useState("/config/images/face_0.png");

  useEffect(() => {
    if (blockchain.errorMsg !== "") {
      setFeedback(blockchain.errorMsg);
    }
  }, [blockchain.errorMsg]);

  
  useEffect(() => {
    const fetchConfig = async () => {
      try {
        const configResponse = await fetch("/config/config.json", {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        });
        const config = await configResponse.json();
        SET_CONFIG(config);

        const totalSupply = await dispatch(fetchTotalSupply());
        setTotalSupply(totalSupply);
      } catch (error) {
        console.error("Error fetching config or totalSupply:", error);
      }
    };

    fetchConfig();
  }, [dispatch]);

  useEffect(() => {
    const moveEyes = (clientX, clientY) => {
      const eyeImg = eyeImgRef.current;
      if (!eyeImg) return;

      const rect = eyeImg.getBoundingClientRect();
      const eyeCenterX = rect.left + rect.width / 2;
      const eyeCenterY = rect.top + rect.height / 2;

      const moveLimitPercent = 2.5;
      const moveLimitX = (window.innerWidth * moveLimitPercent) / 100;
      const moveLimitY = (window.innerHeight * moveLimitPercent * 2) / 100;

      const deltaX = (clientX - eyeCenterX) / 20;
      const deltaY = (clientY - eyeCenterY) / 20;

      const limitedDeltaX = Math.min(Math.max(deltaX, -moveLimitX), moveLimitX);
      const limitedDeltaY = Math.min(Math.max(deltaY, -moveLimitY), moveLimitY);

      eyeImg.style.transform = `translate(${limitedDeltaX}px, ${limitedDeltaY}px)`;
    };

    const handleMouseMove = (event) => {
      moveEyes(event.clientX, event.clientY);
    };

    const handleTouchMove = (event) => {
      const touch = event.touches[0];
      moveEyes(touch.clientX, touch.clientY);
    };

    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("touchmove", handleTouchMove);

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("touchmove", handleTouchMove);
    };
  }, []);

  useEffect(() => {
    const getRandomDuration = (type) => {
      if (type === "face_0") {
        return Math.floor(Math.random() * (3000 - 100 + 1)) + 100; // 100ms ~ 3000ms
      } else if (type === "face_1") {
        return Math.floor(Math.random() * (100 - 30 + 1)) + 30; // 30ms ~ 100ms
      }
    };
  
    const getRandomImageType = () => {
      return Math.random() < 0.5 ? "face_0" : "face_1"; // 랜덤으로 face_0 또는 face_1 선택
    };
  
    let timeoutId;
  
    const changeImage = () => {
      const imageType = getRandomImageType();
      setCurrentImage(`/config/images/${imageType}.png`);
      const duration = getRandomDuration(imageType);
  
      // 이전 타이머를 취소하고 새로운 타이머 설정
      clearTimeout(timeoutId);
      timeoutId = setTimeout(changeImage, duration);
    };
  
    // 첫 타이머 설정
    timeoutId = setTimeout(changeImage, getRandomDuration(getRandomImageType()));
  
    // 컴포넌트 언마운트 시 타이머 정리
    return () => clearTimeout(timeoutId);
  }, []);
  

  const calculateTotalCost = (mintAmount, userNFTBalance, data) => {
    let totalCost = 0;
    const freeMintLeft = data.FREE_NFT - userNFTBalance;

    if (Number(data.totalSupply) < Number(data.freeSupply)) {
      if (freeMintLeft > 0) {
        if (mintAmount <= freeMintLeft) {
          return 0; // Free
        } else {
          totalCost = data.cost * (mintAmount - freeMintLeft);
        }
      } else {
        totalCost = data.cost * mintAmount;
      }
    } else {
      totalCost = data.cost * mintAmount;
    }

    return totalCost;
  };

  const claimNFTs = async (totalCostWei) => {
    setFeedback(`minting your ${data.NFT_NAME}...`);
    setClaimingNft(true);

    try {

      blockchain.smartContract.methods
        .mint(blockchain.account,mintAmount)
        .send({
          to: CONFIG.CONTRACT_ADDRESS,
          from: blockchain.account,
          value: totalCostWei,
        })
        .once("error", (err) => {
          console.log(err);
          setFeedback("something went wrong try again later.");
          setClaimingNft(false);
        })
        .then((receipt) => {
          console.log(receipt);
          setFeedback(
            `your ${CONFIG.NFT_NAME} has been successfully minted.`
          );
          setClaimingNft(false);
          dispatch(fetchData(blockchain.account));
          getUserNFTBalance(); // Fetch balance after minting
        });
    } catch (error) {
      console.log(error);
      setFeedback("something went wrong please try again later.");
      setClaimingNft(false);
    }
  };

  const decrementMintAmount = () => {
    let newMintAmount = mintAmount - 1;
    if (newMintAmount < 1) {
      newMintAmount = 1;
    }
    setMintAmount(newMintAmount);
  };

  const incrementMintAmount = () => {
    let newMintAmount = mintAmount + 1;
    if (newMintAmount > (blockchain.account && blockchain.smartContract ? data.maxMintAmountPerTx : 30)) {
      newMintAmount = blockchain.account && blockchain.smartContract ? data.maxMintAmountPerTx : 30;
    }
    setMintAmount(newMintAmount);
  };

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  const getUserNFTBalance = async () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      const balance = await blockchain.smartContract.methods
        .balanceOf(blockchain.account)
        .call();
      setUserNFTBalance(balance);
    }
  };



  useEffect(() => {
    getData();
    getUserNFTBalance();
  }, [blockchain.account]);

  const totalCostWei = calculateTotalCost(mintAmount, userNFTBalance, data);
  const totalCostEther = (totalCostWei / 10 ** 18).toFixed(10).replace(/\.?0+$/, '');

  useEffect(() => {
    if (blockchain.account && blockchain.smartContract) {
      setFeedback("wallet connected successfully!");
    }
  }, [blockchain.account, blockchain.smartContract]);

  const isError = feedback.includes("change network") || feedback.includes("install Metamask");
  return (
    <Wrapper>
      <Image src={currentImage} alt="face" className="face-img"></Image>
      <Image src="/config/images/eyesback.png" alt="eyesback" className="eyesback-img"></Image>
      <Image src="/config/images/eyes.png" alt="eyes" className="eyes-img" ref={eyeImgRef}></Image>
      {blockchain.account && blockchain.smartContract ? (
        <ConnectedStatus>
          {truncate(blockchain.account, 15)}
        </ConnectedStatus>
      ) : (
        <ConnectButton
          onClick={(e) => {
            e.preventDefault();
            dispatch(connect());
            getData();
            getUserNFTBalance();
          }}
        >
          CONNECT
        </ConnectButton>
      )}
      <MintContainer>
      <StatusContainer>
      <GaugeBarContainer>
        <GaugeBarFill
          fillPercentage={(CONFIG.MAX_FREE_MINTS / CONFIG.MAX_SUPPLY) * 100}
          backgroundColor="#a3a3a3" /* 추가된 값의 색상 */
          
        />
        <GaugeBarFill
          fillPercentage={
            ((blockchain.account && blockchain.smartContract ? data.totalSupply : totalSupply) /
              CONFIG.MAX_SUPPLY) *
            100
          }
          backgroundColor="#333" /* 기존 값의 색상 */
        />

        <GaugeBarText>
          {totalSupply ===0 && data.totalSupply ===0 ? 'mint live' : (
            <>
              {blockchain.account && blockchain.smartContract
                ? `${data.totalSupply} / ${CONFIG.MAX_SUPPLY}`
                : `${totalSupply} / ${CONFIG.MAX_SUPPLY}`}
              
              {(blockchain.account && blockchain.smartContract
                ? CONFIG.MAX_FREE_MINTS - data.totalSupply > 0
                : CONFIG.MAX_FREE_MINTS - totalSupply > 0) && (
                  <>
                    {" ("}
                    {blockchain.account && blockchain.smartContract
                      ? CONFIG.MAX_FREE_MINTS - Number(data.totalSupply)
                      : CONFIG.MAX_FREE_MINTS - totalSupply}{" free mints left)"}
                  </>
                )}
            </>
          )}
        </GaugeBarText>

      </GaugeBarContainer>

        <AmountContainer>
        <AmountButton
          disabled={claimingNft ? 1 : 0}
          onClick={(e) => {
            e.preventDefault();
            decrementMintAmount();
          }}
        >
          -
        </AmountButton>

        <input
          type="number"
          className="amount-input"
          value={mintAmount}
          onChange={(e) => {
            const value = parseInt(e.target.value, 10);
            if (!isNaN(value)) {
              setMintAmount(value);
            }
          }}
          disabled={claimingNft ? 1 : 0}
        />

        <AmountButton
          disabled={claimingNft ? 1 : 0}
          onClick={(e) => {
            e.preventDefault();
            incrementMintAmount();
          }}
        >
          +
        </AmountButton>

        </AmountContainer>
      </StatusContainer>



        <StyledButton
          disabled={claimingNft ? 1 : 0}
          onClick={(e) => {
            e.preventDefault();
            if (Number(CONFIG.MAX_SUPPLY) > (blockchain.account && blockchain.smartContract ? data.totalSupply : totalSupply)) {
              if (!blockchain.account || !blockchain.smartContract) {
                dispatch(connect());
                getData();
                getUserNFTBalance();
              } else {
                claimNFTs(totalCostWei);
                getData();
                getUserNFTBalance();
              }
            } else {
              window.open(CONFIG.MARKETPLACE_LINK, "_blank");
            }
          }}
        >
          <MintButtonText>
            {Number(CONFIG.MAX_SUPPLY) > (blockchain.account && blockchain.smartContract ? data.totalSupply : totalSupply)
              ? (claimingNft ? "minting.." : "MINT")
              : "BUY"}
          </MintButtonText>
          <PriceText>
            {Number(CONFIG.MAX_SUPPLY) <= (blockchain.account && blockchain.smartContract ? data.totalSupply : totalSupply)
              ? "sold out"
              : blockchain.account && blockchain.smartContract
              ? totalCostWei === 0 
                ? "FREE"
                : `${totalCostEther} ${CONFIG.NETWORK.SYMBOL}`
              : mintAmount <= CONFIG.FREE_MINTS 
              ? "FREE"
                : `${Number(CONFIG.DISPLAY_COST * (mintAmount - CONFIG.FREE_MINTS)).toFixed(10).replace(/\.?0+$/, '')} ${CONFIG.NETWORK.SYMBOL}`}
          </PriceText>
        </StyledButton>

        <Feedback isError={isError}>{feedback}</Feedback>
      </MintContainer>


      <InfoIcon onClick={() => setShowModal(true)}>?</InfoIcon>

      {showModal && (
        <>
          <ModalOverlay onClick={() => setShowModal(false)} />
          <Modal>
            dengboz mint info
            
            <CloseButton onClick={() => setShowModal(false)}> 𝗫</CloseButton>
            <MintInfo>
            <div>FREE mint</div>
            <br></br>
              <tr>
                <td className="label">free mints supply</td>
                <td className="value">
                  : {CONFIG.MAX_FREE_MINTS}
                </td>
              </tr>
              <tr>
                <td className="label">max supply</td>
                <td className="value">: {CONFIG.MAX_SUPPLY}</td>
              </tr>
              <tr>
                <td className="label">multiple mints price</td>
                <td className="value">
                  : {CONFIG.DISPLAY_COST} {CONFIG.NETWORK.SYMBOL}
                </td>
              </tr>
              <tr>
                <td className="label">smart contract</td>
                <td className="value">
                  : ERC721AC 
                </td>
              </tr>
              <br></br>
              <div>{CONFIG.FREE_MINTS} free mints available per wallet.</div>
              <div>minting multiple nfts in one transaction costs {CONFIG.DISPLAY_COST} eth.</div>
              <div>{CONFIG.DISPLAY_COST} ETH per nft after the free mints supply.</div>

              <br></br>
              <div>&copy; developed by dengboz</div>

            </MintInfo>
          </Modal>
        </>
      )}
    </Wrapper>
  );
}

export default App;
